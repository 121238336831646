import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { iCustomerNewsRead } from 'datamodel';

const initialState: iCustomerNewsRead[] = [];

export const newsSlice = createSlice({
  name: 'customerNews',
  initialState,
  reducers: {
    setNewsValue: (state, action: PayloadAction<iCustomerNewsRead[]>) => {
      return action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setNewsValue, reset } = newsSlice.actions;

export default newsSlice.reducer;
