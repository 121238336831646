import { deDE as coreDeDE, enUS as coreEnUS } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { deDE, enUS } from '@mui/x-data-grid';
import { deDE as pickersDeDE, enUS as pickersEnUS } from '@mui/x-date-pickers/locales';
import { theme as sharedTheme } from 'shared';

const theme = createTheme({});

// export const primary = {
//   light: sharedTheme.colors.primaryLight,
//   main: sharedTheme.colors.primary,
//   dark: sharedTheme.colors.primaryDark,
//   contrastText: sharedTheme.colors.white,
// };
// export const secondary = {
//   light: sharedTheme.colors.secondaryLight,
//   main: sharedTheme.colors.secondary,
//   dark: sharedTheme.colors.secondaryDark,
//   contrastText: sharedTheme.colors.black,
// };

// export const statusLights = sharedTheme.colors.lights as iStatusLights;
// export const secondaryText = sharedTheme.colors.blackRgba(0.5);

export const appBarBackground = sharedTheme.colors.surface;
// export const calendarColors = {
//   today: sharedTheme.colors.primaryRgba(0.75),
//   other: sharedTheme.colors.surfaceDark,
// };

// export const pageHeaderBackground = sharedTheme.colors.surfaceDark;

const baseTheme = (lng: 'de' | 'en') => {
  return createTheme(
    {
      palette: {
        primary: {
          main: sharedTheme.products.crewlinq.theme.primaryColor.primary,
          light: sharedTheme.products.crewlinq.theme.primaryColor.primaryLight,
          dark: sharedTheme.products.crewlinq.theme.primaryColor.primaryDark,
          contrastText: sharedTheme.products.crewlinq.theme.primaryColor.primaryContrastText,
        },
        secondary: {
          main: sharedTheme.products.crewlinq.theme.secondaryColor.secondary,
          light: sharedTheme.products.crewlinq.theme.secondaryColor.secondaryLight,
          dark: sharedTheme.products.crewlinq.theme.secondaryColor.secondaryDark,
          contrastText: sharedTheme.products.crewlinq.theme.secondaryColor.secondaryContrastText,
        },
        text: {
          primary: sharedTheme.products.crewlinq.theme.text.primary,
          secondary: sharedTheme.products.crewlinq.theme.text.secondary,
        },
        error: {
          main: sharedTheme.products.leasing.theme.errorColor.error,
        },
      },
      typography: {
        fontFamily:
          'Roboto, hk-grotesk, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
        body1: { lineHeight: 1.7 },
        h1: { fontSize: '2.5rem', marginBottom: theme.spacing(1) },
        h2: { fontSize: '2rem', marginBottom: theme.spacing(1) },
        h3: { fontSize: '1.75rem', marginBottom: theme.spacing(1) },
        h4: { fontSize: '1.5rem', marginBottom: theme.spacing(1) },
        h5: { fontSize: '1.25rem', marginBottom: theme.spacing(1) },
        h6: { fontSize: '1rem', marginBottom: theme.spacing(1) },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              ':before': {
                display: 'none',
              },
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: { marginBottom: theme.spacing(1) },
          },
        },
        MuiCard: {
          defaultProps: {
            elevation: 5,
          },
          styleOverrides: {
            root: {
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'unset',
            },
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            root: {
              padding: theme.spacing(1),
              overflow: 'unset',
              textAlign: 'center',
              borderRadius: theme.shape.borderRadius,
              color: theme.palette.getContrastText(sharedTheme.products.crewlinq.theme.muiCardHeader.textColor),
              background: sharedTheme.products.crewlinq.theme.muiCardHeader.backgroundColor,
            },
          },
        },
        MuiCardActionArea: {
          styleOverrides: {
            root: {
              padding: theme.spacing(1),
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              marginTop: theme.spacing(1),
              padding: theme.spacing(2),
              flex: '1 1 auto',
              position: 'relative',
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              backgroundColor: sharedTheme.products.crewlinq.canvas.backgroundColor,
              overflow: 'hidden',
              border: 'none',
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              padding: theme.spacing(2),
              overflow: 'unset',
              textAlign: 'center',
              color: sharedTheme.products.crewlinq.theme.muiDialogTitle.textColor,
              background: sharedTheme.products.crewlinq.theme.muiDialogTitle.backgroundColor,
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              marginTop: theme.spacing(1),
              padding: theme.spacing(2),
              flex: '1 1 auto',
              position: 'relative',
            },
          },
        },
      },
    },
    lng === 'de' ? deDE : enUS,
    lng === 'de' ? pickersDeDE : pickersEnUS,
    lng === 'de' ? coreDeDE : coreEnUS,
  );
};

export const crewLinQTheme = (lng: 'de' | 'en') => {
  return createTheme(
    deepmerge(
      baseTheme(lng),
      createTheme({
        palette: {
          primary: {
            main: sharedTheme.products.crewlinq.theme.primaryColor.primary,
            light: sharedTheme.products.crewlinq.theme.primaryColor.primaryLight,
            dark: sharedTheme.products.crewlinq.theme.primaryColor.primaryDark,
            contrastText: sharedTheme.products.crewlinq.theme.primaryColor.primaryContrastText,
          },
          secondary: {
            main: sharedTheme.products.crewlinq.theme.secondaryColor.secondary,
            light: sharedTheme.products.crewlinq.theme.secondaryColor.secondaryLight,
            dark: sharedTheme.products.crewlinq.theme.secondaryColor.secondaryDark,
            contrastText: sharedTheme.products.crewlinq.theme.secondaryColor.secondaryContrastText,
          },
          text: {
            primary: sharedTheme.products.crewlinq.theme.text.primary,
            secondary: sharedTheme.products.crewlinq.theme.text.secondary,
          },
          error: {
            main: sharedTheme.products.leasing.theme.errorColor.error,
          },
        },
        components: {
          MuiCardHeader: {
            styleOverrides: {
              root: {
                color: theme.palette.getContrastText(sharedTheme.products.crewlinq.theme.muiCardHeader.textColor),
                background: sharedTheme.products.crewlinq.theme.muiCardHeader.backgroundColor,
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: sharedTheme.products.crewlinq.canvas.backgroundColor,
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                color: sharedTheme.products.crewlinq.theme.muiDialogTitle.textColor,
                background: sharedTheme.products.crewlinq.theme.muiDialogTitle.backgroundColor,
              },
            },
          },
        },
      }),
    ),
  );
};

export const leasingTheme = (lng: 'de' | 'en') => {
  return createTheme(
    deepmerge(
      baseTheme(lng),
      createTheme({
        palette: {
          primary: {
            main: sharedTheme.products.leasing.theme.primaryColor.primary,
            light: sharedTheme.products.leasing.theme.primaryColor.primaryLight,
            dark: sharedTheme.products.leasing.theme.primaryColor.primaryDark,
            contrastText: sharedTheme.products.leasing.theme.primaryColor.primaryContrastText,
          },
          secondary: {
            main: sharedTheme.products.leasing.theme.secondaryColor.secondary,
            light: sharedTheme.products.leasing.theme.secondaryColor.secondaryLight,
            dark: sharedTheme.products.leasing.theme.secondaryColor.secondaryDark,
            contrastText: sharedTheme.products.leasing.theme.secondaryColor.secondaryContrastText,
          },
          text: {
            primary: sharedTheme.products.leasing.theme.text.primary,
            secondary: sharedTheme.products.leasing.theme.text.secondary,
          },
          error: {
            main: sharedTheme.products.leasing.theme.errorColor.error,
          },
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: `
       & .MuiDataGrid-columnHeader, & .MuiDataGrid-columnHeaderWrapper': {
            backgroundColor: ${sharedTheme.products.leasing.canvas.backgroundColor},
             color: 'white',
           },
         }}
      `,
          },
          MuiTableHead: {
            styleOverrides: {
              root: {
                backgroundColor: sharedTheme.products.leasing.canvas.backgroundColor,
              },
            },
          },

          MuiCardHeader: {
            styleOverrides: {
              root: {
                color: theme.palette.getContrastText(sharedTheme.products.leasing.theme.muiCardHeader.textColor),
                background: sharedTheme.products.leasing.theme.muiCardHeader.backgroundColor,
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: sharedTheme.products.leasing.canvas.backgroundColor,
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                color: sharedTheme.products.leasing.theme.muiDialogTitle.textColor,
                background: sharedTheme.products.leasing.theme.muiDialogTitle.backgroundColor,
              },
            },
          },
        },
      }),
    ),
  );
};

export const homeCareTheme = (lng: 'de' | 'en') => {
  return createTheme(
    deepmerge(
      baseTheme(lng),
      createTheme({
        palette: {
          primary: {
            main: sharedTheme.products.homeCare.theme.primaryColor.primary,
            light: sharedTheme.products.homeCare.theme.primaryColor.primaryLight,
            dark: sharedTheme.products.homeCare.theme.primaryColor.primaryDark,
            contrastText: sharedTheme.products.homeCare.theme.primaryColor.primaryContrastText,
          },
          secondary: {
            main: sharedTheme.products.homeCare.theme.secondaryColor.secondary,
            light: sharedTheme.products.homeCare.theme.secondaryColor.secondaryLight,
            dark: sharedTheme.products.homeCare.theme.secondaryColor.secondaryDark,
            contrastText: sharedTheme.products.homeCare.theme.secondaryColor.secondaryContrastText,
          },
          text: {
            primary: sharedTheme.products.homeCare.theme.text.primary,
            secondary: sharedTheme.products.homeCare.theme.text.secondary,
          },
          error: {
            main: sharedTheme.products.leasing.theme.errorColor.error,
          },
        },
        components: {
          MuiCardHeader: {
            styleOverrides: {
              root: {
                color: theme.palette.getContrastText(sharedTheme.products.homeCare.theme.muiCardHeader.textColor),
                background: sharedTheme.products.homeCare.theme.muiCardHeader.backgroundColor,
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: sharedTheme.products.homeCare.canvas.backgroundColor,
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                color: sharedTheme.products.homeCare.theme.muiDialogTitle.textColor,
                background: sharedTheme.products.homeCare.theme.muiDialogTitle.backgroundColor,
              },
            },
          },
        },
      }),
    ),
  );
};
