export enum eAddOns {
  AusfallManagement = 'AusfallManagement',
  Chat = 'Chat',
  MultiTenant = 'MultiTenant',
  API = 'API',
  PoolManagement = 'PoolManagement',
  LeasingCustomer = 'LeasingCustomer',
  NotificationCenter = 'NotificationCenter',
  Export = 'Export',
  Manager = 'Manager',
  VolunteerManagement = 'VolunteerManagement',
  DeploymentLocations = 'DeploymentLocations',
  ShiftPlanInApp = 'ShiftPlanInApp',
  ShiftPlanForAdmin = 'ShiftPlanForAdmin',
}

export enum eLeasingAddOns {
  NotificationCenter = 'NotificationCenter',
  TimeManager = 'TimeManager',
  App = 'App',
  AllItemsOverview = 'AllItemsOverview',
  ExternalCustomers = 'ExternalCustomers',
}

export enum eUserRoles {
  SuperAdmin = 'superAdmin',
  Admin = 'admin',
  User = 'user',
  LeasingAdmin = 'leasingAdmin',
}

export enum eCrewlinqRights {
  AusfallAdmin = 'ausfallAdmin',
  User = 'user',
  PoolAdmin = 'poolAdmin',
  LeasingAdmin = 'leasingAdmin',
  GlobalAdmin = 'globalAdmin',
  VolunteerDepartmentAdmin = 'volunteerDepartmentAdmin',
  VolunteerGlobalAdmin = 'volunteerGlobalAdmin',
}

export enum eUserCapabilities {
  PoolUser = 'poolUser',
  PoolAdmin = 'poolAdmin',
}

export enum eSendToWhom {
  OwnDepartment = 'ownDepartment',
  DepartmentList = 'departmentList',
  RegionList = 'regionList',
  OnQualifications = 'onQualifications',
  ExternalList = 'externalList',
  PoolRequest = 'poolRequest',
  Employees = 'employees',
  DeploymentLocations = 'deploymentLocations',
}

export enum eOnboardingStatus {
  Qualifications = 'qualifications',
  Shifts = 'shifts',
  Texts = 'texts',
  Departments = 'departments',
  LastStep = 'lastStep',
  Done = 'done',
}

export enum eOfferStatus {
  NewOffer = 'newOffer',
  Accepted = 'accepted',
  Assigned = 'assigned',
  Rejected = 'rejected',
  Unknown = 'unknown',
}

export enum ePoolItemRequestStatus {
  New = 'new',
  Accepted = 'accepted',
  Completed = 'completed',
  Cancelled = 'cancelled',
  NoOptions = 'noOptions',
}

export enum ePoolItemStatus {
  Option = 'option',
  Accepted = 'accepted',
  Offered = 'offered',
  Rejected = 'rejected',
  Unavailable = 'unavailable',
  Vacation = 'vacation',
  BookedOnDay = 'bookedOnDay',
  Requested = 'requested',
  RejectedByPoolManager = 'rejectedByPoolManager',
}

export enum eOfferTypes {
  LeasingOffer = 'leasingOffer',
  PoolItem = 'poolItem',
  PoolItemRequest = 'poolItemRequest',
  Offer = 'offer',
  DeploymentLocationOffer = 'deploymentLocationOffer',
  HomeCareOffer = 'homeCareOffer',
  LeasingEmployeeOffer = 'leasingEmployeeOffer',
  LeasingExternalCustomerOffer = 'leasingExternalCustomerOffer',
  VolunteerOffer = 'volunteerOffer',
  VolunteerOfferAdmin = 'volunteerOfferAdmin',
  ShiftPlanItem = 'shiftPlanItem',
}

export enum eLeasingRequestStatus {
  New = 'new',
  HasOffer = 'hasOffer',
  Accepted = 'accepted',
  Completed = 'completed',
  Cancelled = 'cancelled',
  NoOptions = 'noOptions',
}

export enum eLeasingExternalCustomerStatus {
  New = 'new',
  Completed = 'completed',
  Cancelled = 'cancelled',
  NoOptions = 'noOptions',
}

export enum eLeasingRequestOfferStatus {
  New = 'new',
  Offered = 'offered',
  Accepted = 'accepted',
  AcceptedOtherOffer = 'acceptedOtherOffer',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
  Completed = 'completed',
  CancelledByCustomer = 'cancelledByCustomer',
}

export enum eLeasingEmployeeOfferStatus {
  New = 'new',
  Accepted = 'accepted',
  Expired = 'expired',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
}

export enum eAccessRights {
  User = 'user',
  InternalAdmin = 'internalAdmin',
}

export enum eShiftPartOfDay {
  Early = 'e',
  Middle = 'm',
  Late = 'l',
  Night = 'n',
  None = '',
}

// Colors match import { AlertColor } from '@mui/material';
export enum eNotificationSeverity {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}

export enum eHospitalType {
  Allgemeinkrankenhaus = 'Allgemeinkrankenhaus',
  Fachkrankenhaus = 'Fachkrankenhaus',
  Kinderkrankenhaus = 'Kinderkrankenhaus',
  Rehabilitationsklinik = 'Rehabilitationsklinik',
  Pflegeheim = 'Pflegeheim',
  Hospize = 'Hospize',
  Psychiatrie = 'Psychiatrie',
  Tagesklinik = 'Tagesklinik',
  Notfallversorgung = 'Notfallversorgung',
}

export enum eNotificationType {
  leasingRequest = 'leasingRequest',
  leasingEmployeeOffer = 'leasingEmployeeOffer',
  crewlinqOffer = 'crewlinqOffer',
  poolItem = 'poolItem',
  homeCareOffer = 'homeCareOffer',
  volunteerOffer = 'volunteerOffer',
}

export enum eVolunteerOfferStatus {
  New = 'new',
  Accepted = 'accepted',
  PendingAdminApproval = 'pendingAdminApproval',
  PendingUserChoice = 'pendingUserChoice',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Rejected = 'rejected',
}

export enum eManagerCards {
  totalOffers = 'totalOffers',
  totalReactions = 'totalReactions',
  offersAssigned = 'offersAssigned',
  acceptVsReject = 'acceptVsReject',
  averagePositiveResponse = 'averagePositiveResponse',
  averageLeadTime = 'averageLeadTime',
  topThreeLeadTime = 'topThreeLeadTime',
  mostRequired = 'mostRequired',
  timeSaved = 'timeSaved',
  mostRequiredShift = 'mostRequiredShift',
  offerDistribution = 'offerDistribution',
  frequentUsers = 'frequentUsers',
  userNumbers = 'userNumbers',
  requestToShiftTime = 'requestToShiftTime',
  requestedVsBooked = 'requestedVsBooked',
  leasingRequestedVsBooked = 'leasingRequestedVsBooked',
  leasingMostRequested = 'leasingMostRequested',
  leasingTopThreePartners = 'leasingTopThreePartners',
  leasingTopThreeUsers = 'leasingTopThreeUsers',
  leasingRequestToShiftTime = 'leasingRequestToShiftTime',
  poolWorkload = 'poolWorkload',
  poolRequestToShiftTime = 'poolRequestToShiftTime',
  poolMostRequested = 'poolMostRequested',
  jokerOfferedVsBooked = 'jokerOfferedVsBooked',
  jokerDepartmentSelection = 'jokerDepartmentSelection',
  cancelledOffers = 'cancelledOffers',
}

export enum eShiftType {
  Shift = 'shift',
  PoolShift = 'poolShift',
}

export enum eLeasingRequestShiftType {
  OneShift = 'oneShift',
  CustomShifts = 'customShifts',
  OpenShifts = 'openShifts',
}

export enum eAPISuppliers {
  Connext = 'connext',
}

export enum eApiSaveStatus {
  Pending = 'pending',
  Saved = 'saved',
  Warning = 'warning',
  Error = 'error',
}

export enum eApiUserAvailabilityStatus {
  Available = 'available',
  Unavailable = 'unavailable',
  Pending = 'pending',
  Unknown = 'unknown',
}

export enum eStatisticsStatus {
  Success = 'success',
  Error = 'error',
  Warn = 'warn',
}

export enum eApiSyncCategory {
  Employees = 'employees',
  Departments = 'departments',
  Qualifications = 'qualifications',
  Shifts = 'shifts',
  FixDepartmentsQualifications = 'fix-departments-qualifications',
}

export enum eExternalCustomerShiftType {
  OneShift = 'oneShift',
  CustomShifts = 'customShifts',
}

export enum eApiTrackingAction {
  Login = 'login',
  Logout = 'logout',
  PageView = 'page_view',
  Sleep = 'sleep',
  Wake = 'wake',
}
