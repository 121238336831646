/**
 * This is the user record from the company, here different values and settings can be stored
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface iSizes {
  sidebarWidth: number;
  headerHeight: number;
  notificationWidth: number;
  titleBarHeight: number;
  centerPaddingWidth: number;
  centerPaddingHeight: number;
  appBarHeight: number;
}

export const sizesSlice = createSlice({
  name: 'sizes',
  initialState: {
    titleBarHeight: 0,
    sidebarWidth: 0,
    headerHeight: 0,
    notificationWidth: 0,
    centerPaddingWidth: 32,
    centerPaddingHeight: 32,
    appBarHeight: 0,
  } as iSizes,
  reducers: {
    setSizesValue: (state, action: PayloadAction<Partial<iSizes>>) => ({ ...state, ...action.payload }),
  },
});

export const { setSizesValue } = sizesSlice.actions;

export default sizesSlice.reducer;
