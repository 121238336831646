import { configureStore } from '@reduxjs/toolkit';
import { appStateSlice } from './slices/appState';
import { customerNewsSlice } from './slices/customerNews';
import { departmentsSlice } from './slices/departments';
import { deploymentLocationsSlice } from './slices/deploymentLocation';
import { featuresSlice } from './slices/features';
import { homeCareServicesSlice } from './slices/homeCareServices';
import { hooverCalendarItemSlice } from './slices/hooverCalendarItem';
import { hooverLeasingRequestSlice } from './slices/hooverLeasingRequest';
import { isLoadingSlice } from './slices/isLoading';
import { leasingCustomerShiftsSlice } from './slices/leasingCustomerShifts';
import { leasingEmployeeOffersSlice } from './slices/leasingEmployeeOffers';
import { leasingEmployeesSlice } from './slices/leasingEmployees';
import { LeasingExternalCustomerOffersSlice } from './slices/leasingExternalCustomerOffers';
import { leasingExternalCustomersSlice } from './slices/leasingExternalCustomers';
import { leasingQualificationsSlice } from './slices/leasingQualifications';
import { leasingRequestsSlice } from './slices/leasingRequests';
import { localHolidaysSlice } from './slices/localHolidays';
import { newsSlice } from './slices/news';
import { notificationsSlice } from './slices/notifications';
import { poolShiftsSlice } from './slices/poolShifts';
import { poolUserDetailsSlice } from './slices/poolUserDetails';
import { qualificationsSlice } from './slices/qualifications';
import { shiftsSlice } from './slices/shifts';
import { sizesSlice } from './slices/sizes';
import { timeSlotsSlice } from './slices/timeSlots';
import { userSlice } from './slices/user';
import { userObjectSlice } from './slices/userObject';
import { userRecordSlice } from './slices/userRecord';

const store = configureStore({
  reducer: {
    isLoading: isLoadingSlice.reducer,
    userObject: userObjectSlice.reducer,
    user: userSlice.reducer,
    appState: appStateSlice.reducer,
    departments: departmentsSlice.reducer,
    shifts: shiftsSlice.reducer,
    qualifications: qualificationsSlice.reducer,
    timeSlots: timeSlotsSlice.reducer,
    homeCareServices: homeCareServicesSlice.reducer,
    hooverCalendarItem: hooverCalendarItemSlice.reducer,
    hooverLeasingRequest: hooverLeasingRequestSlice.reducer,
    leasingQualifications: leasingQualificationsSlice.reducer,
    leasingEmployees: leasingEmployeesSlice.reducer,
    leasingRequests: leasingRequestsSlice.reducer,
    leasingEmployeeOffers: leasingEmployeeOffersSlice.reducer,
    leasingExternalCustomerOffers: LeasingExternalCustomerOffersSlice.reducer,
    notifications: notificationsSlice.reducer,
    userRecord: userRecordSlice.reducer,
    features: featuresSlice.reducer,
    sizes: sizesSlice.reducer,
    leasingExternalCustomers: leasingExternalCustomersSlice.reducer,
    poolUserDetails: poolUserDetailsSlice.reducer,
    leasingCustomerShifts: leasingCustomerShiftsSlice.reducer,
    localHolidays: localHolidaysSlice.reducer,
    poolShifts: poolShiftsSlice.reducer,
    deploymentLocations: deploymentLocationsSlice.reducer,
    news: newsSlice.reducer,
    customerNews: customerNewsSlice.reducer,
  },
  devTools: process.env.NODE_ENV === 'development' || window.location.hostname.includes('crewlinq-dev'),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   // Ignore these action types
      //   ignoredActions: ['user', 'userObject', 'UserImpl', 'setUserObjectValue'],
      //   // Ignore these field paths in all actions
      //   ignoredActionPaths: ['userObject', 'userObject/setUserObjectValue'],
      //   // Ignore these paths in the state
      //   ignoredPaths: ['userObject'],
      // },
      thunk: true, // TODO: Check if this is needed
      immutableCheck: false, // TODO: Check if this is needed
      serializableCheck: false, // TODO: Fix these issues (user object i guess)
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
