// https://pinetools.com/lighten-color 15%
// https://pinetools.com/lighten-darken-color 15%

export const products = {
  leasing: {
    primary: '#8994d2',
    primaryLight: '#bac0e4',
    primaryDark: '#5b6bca',
    primaryDarkRgba: (opacity: number) => `rgba(91, 107, 202, ${opacity})`,
    primaryRgb: 'rgb(137, 148, 210)',
    primaryRgba: (opacity: number) => `rgba(137, 148, 210, ${opacity})`,
    // 50% darker -> '#2d2e6a', 50% darker -> '#16172f' 50% darker -> '#0b0b17' 50% darker -> '#05050b'
    secondaryFontColor: '#5b6bca',
    secondaryFontColorRgb: `rgb(91, 107, 202)`,
    secondaryFontColorRgba: (opacity: number) => `rgba(91, 107, 202, ${opacity})`,
  },
  crewlinq: {
    primary: '#4ba7a4',
    primaryRgb: 'rgb(75, 167, 164)',
    primaryRgba: (opacity: number) => `rgba(75, 167, 164, ${opacity})`,
    primaryLight: '#bcdedd',
    primaryDark: '#1f6d71',
    // 50% darker -> '#0f3638', 50% darker -> '#071b1c' 50% darker -> '#030e0f' 50% darker -> '#010707'
    secondaryFontColor: '#1f6d71',
    secondaryFontColorRgb: `rgb(31, 109, 113)`,
    secondaryFontColorRgba: (opacity: number) => `rgba(31, 109, 113, ${opacity})`,
  },
  homeCare: {
    primary: '#4ba7a4',
    primaryRgb: 'rgb(75, 167, 164)',
    primaryRgba: (opacity: number) => `rgba(75, 167, 164, ${opacity})`,
    primaryLight: '#bcdedd',
    primaryDark: '#1f6d71',
    // 50% darker -> '#0f3638', 50% darker -> '#071b1c' 50% darker -> '#030e0f' 50% darker -> '#010707'
    secondaryFontColor: '#1f6d71',
    secondaryFontColorRgb: `rgb(31, 109, 113)`,
    secondaryFontColorRgba: (opacity: number) => `rgba(31, 109, 113, ${opacity})`,
  },
  core: {
    primary: '#0a223e',
    primaryRgb: 'rgb(10, 34, 62)',
    primaryRgba: (opacity: number) => `rgba(10, 34, 62, ${opacity})`,
    secondary: '#df9992',
    secondaryRgb: 'rgb(223, 153, 146)',
    secondaryRgba: (opacity: number) => `rgba(223, 153, 146, ${opacity})`,
  },
  error: {
    primary: '#fb0026',
  },
  grey: {
    dark: '#404040',
    normal: '#8c8c8c',
    normalRgba: (opacity: number) => `rgba(140, 140, 140, ${opacity})`,
    light: '#c5c5c5',
    lightRgba: (opacity: number) => `rgba(197, 197, 197, ${opacity})`,
  },
};

export const primary = products.crewlinq.primary;
export const primaryLight = products.crewlinq.primaryLight;
export const primaryDark = products.crewlinq.primaryDark;

export const primaryRgb = products.crewlinq.primaryRgb;
export const primaryRgba = (opacity: number) => `rgba(75, 167, 164, ${opacity})`;

export const secondary = products.core.primary;
export const secondaryLight = '#004582';
export const secondaryDark = '#001c36';
export const secondaryRgb = 'rgb(10, 34, 62)';
export const secondaryRgba = (opacity: number) => `rgba(10, 34, 62, ${opacity})`;

export const accent = products.core.secondary;
export const accentRgb = products.core.secondaryRgb;
export const accentRgba = (opacity: number) => `rgba(235, 148, 142, ${opacity})`;

export const background = '#b2dfdd';
export const backgroundRgb = 'rgb(178, 223, 221)';
export const backgroundRgba = (opacity: number) => `rgba(178, 223, 221, ${opacity})`;

export const surface = '#F6F6F8';
export const surfaceDark = '#EEF5F9';
export const surfaceRgb = 'rgb(246, 246, 248)';
export const surfaceRgba = (opacity: number) => `rgba(246, 246, 248, ${opacity})`;

export const backgroundOverlay = 'rgba(58, 53, 65, 0.1)';
export const backgroundOverlayRgba = (opacity: number) => `rgba(58, 53, 65, ${opacity})`;

export const whiteOverlay = '#F5FCFF';
export const whiteOverlayRgba = (opacity: number) => `rgba(245, 252, 255, ${opacity})`;

export const primaryFontColor = secondary;
export const primaryFontColorRgb = secondaryRgb;
export const primaryFontColorRgba = (opacity: number) => secondaryRgba(opacity);

export const secondaryFontColor = primary;
export const secondaryFontColorRgb = primaryRgb;
export const secondaryFontColorRgba = (opacity: number) => primaryRgba(opacity);

export const accentFontColor = '#FFF';
export const accentFontColorRgb = 'rgb(255, 255, 255)';
export const accentFontColorRgba = (opacity: number) => `rgba(255, 255, 255, ${opacity})`;

export const white = '#ffffff';
export const whiteRgb = 'rgb(255, 255, 255)';
export const whiteRgba = (opacity: number) => `rgba(255, 255, 255, ${opacity})`;

export const black = '#000000';
export const blackRgb = 'rgb(0, 0, 0)';
export const blackRgba = (opacity: number) => `rgba(0, 0, 0, ${opacity})`;

export const dropShadow = 'rgba(0,0,0,0.32)';
export const yellowShadow = '#ffb10160';

export const border = {
  lightest: '#e2e2e2',
  lighter: '#b8b8b8',
  light: '#acacac',
  normal: '#9e9e9e',
  dark: '#868686',
  rgba(opacity: number) {
    return `rgba(158, 158, 158, ${opacity})`;
  },
};

export const blackFont = { light: '#515151', normal: '#070707', dark: '#040404' };

export const lights = {
  green: {
    light: '#b2e4d8',
    normal: '#5abda6',
    dark: '#009774',
    transparent: (opacity: number) => `rgba(0, 177, 114, ${opacity})`,
  },
  yellow: {
    light: '#ffefcb',
    normal: '#ffdf98',
    dark: '#ffc953',
    transparent: (opacity: number) => `rgba(255, 193, 7, ${opacity})`,
  },
  red: {
    light: '#ecbdc2',
    normal: '#d97c86',
    dark: '#c02535',
    transparent: (opacity: number) => `rgba(244, 67, 54, ${opacity})`,
  },
  neutral: {
    light: '#c5c5c5',
    // lighter than light by 20%
    lighter: '#e2e2e2',
    // lighter than light by 40%
    lightest: '#f2f2f2',
    normal: '#8c8c8c',
    dark: '#404040',
    transparent: (opacity: number) => `rgba(189, 189, 189, ${opacity})`,
  },
  blue: {
    light: '#42a5f4',
    normal: '#2196F3',
    dark: '#0c81de',
    transparent: (opacity: number) => `rgba(33, 150, 243, ${opacity})`,
  },
};

export const disabled = 'rgba(0, 0, 0, 0.38)';
