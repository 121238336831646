import { checkCustomerId, checkTenantId, checkUserId } from 'database';
import { collection, deleteDoc, doc, increment, setDoc, updateDoc } from 'firebase/firestore';
import { db } from 'localFirebase';

export const getCustomerNewsRef = (newsId: string) => {
  const tenantId = checkTenantId();
  const customerId = checkCustomerId();
  const uid = checkUserId();
  if (!tenantId) return false;
  if (!customerId) return false;
  if (!uid) return false;
  const customerNews = doc(db, 'customers', customerId, 'houses', tenantId, 'users', uid, 'customerNews', newsId);
  return customerNews;
};

export const addLike = async (newsId: string) => {
  const ref = getCustomerNewsRef(newsId);
  if (!ref) return;
  try {
    await updateDoc(ref, {
      isThumbUp: true,
    });
  } catch (error) {
    console.error('Fehler beim Hinzufügen des Likes: ', error);
  }
};

export const addLikeCount = async (newsId: string) => {
  const newsRef = doc(db, 'newsReactions', newsId);
  await updateDoc(newsRef, {
    thumbUpCount: increment(1),
  });
};

export const removeLikeCount = async (newsId: string) => {
  const newsRef = doc(db, 'newsReactions', newsId);
  await updateDoc(newsRef, {
    thumbUpCount: increment(-1),
  });
};

export const addFavoriteCount = async (newsId: string) => {
  const newsRef = doc(db, 'newsReactions', newsId);
  await updateDoc(newsRef, {
    favoritesCount: increment(1),
  });
};

export const removeFavoriteCount = async (newsId: string) => {
  const newsRef = doc(db, 'newsReactions', newsId);
  await updateDoc(newsRef, {
    favoritesCount: increment(-1),
  });
};

export const removeLike = async (newsId: string) => {
  const ref = getCustomerNewsRef(newsId);

  if (!ref) return;
  try {
    await updateDoc(ref, {
      isThumbUp: false,
    });
  } catch (error) {
    console.error('Fehler beim Hinzufügen des Likes: ', error);
  }
};

export const addFavorite = async (newsId: string) => {
  const ref = getCustomerNewsRef(newsId);
  if (!ref) return;
  try {
    await updateDoc(ref, {
      isFavorite: true,
    });
  } catch (error) {
    console.error('Fehler beim Hinzufügen zu Favoriten: ', error);
  }
};

export const removeFavorite = async (newsId: string) => {
  const ref = getCustomerNewsRef(newsId);
  if (!ref) return;
  try {
    await updateDoc(ref, {
      isFavorite: false,
    });
  } catch (error) {
    console.error('Fehler beim Entfernen von Favoriten: ', error);
  }
};

export const addBlankNewsReaction = async (): Promise<string> => {
  const releaseNotesDocuments = doc(collection(db, 'newsReactions'));
  return releaseNotesDocuments.id;
};

export const updateNewsReactionById = async (id: string, noteId: string): Promise<void> => {
  const newsReactionsRef = doc(db, 'newsReactions', id);
  if (!newsReactionsRef) return;
  const res = await setDoc(newsReactionsRef, { noteId }, { merge: true });
  return res;
};

export const deleteNewsReactionById = async (id: string): Promise<void> => {
  const newsReactionsRef = doc(db, 'newsReactions', id);
  if (!newsReactionsRef) return;
  const res = await deleteDoc(newsReactionsRef);
  return res;
};
