import { checkTenantId, checkUserId } from 'database';
import {
  iHomeCareExportSettings,
  iHomeCareOffer,
  iHomeCareUser,
  iQualification,
  iRegion,
  iService,
  iTimeSlot,
} from 'datamodel';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Query,
  QuerySnapshot,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from 'localFirebase';

const debug = false;

export const getHomeRef = (): DocumentReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const home = doc(db, 'homeCare', tenantId);
  return home;
};

export const getUserRef = (): DocumentReference<DocumentData> | false => {
  if (debug) console.log('getUserRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const userId = checkUserId();
  if (!userId) return false;
  const user = doc(db, 'homeCare', tenantId, 'users', userId);
  return user;
};

export const getAUserRef = (uid: string, pTenantId?: string): DocumentReference<DocumentData> | false => {
  if (debug) console.log('getAUserRef');
  const tenantId = pTenantId || checkTenantId();
  if (!tenantId) return false;
  const user = doc(db, 'homeCare', tenantId, 'users', uid);
  return user;
};

export const getAUserDoc = async (uid: string, pTenantId?: string): Promise<iHomeCareUser | false> => {
  const userRef = getAUserRef(uid, pTenantId);
  if (!userRef) return false;
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) return { id: userDoc.id, ...userDoc.data() } as iHomeCareUser;
  return false;
};

export const updateUser = async (value: Partial<iHomeCareUser>): Promise<void> => {
  if (debug) console.log('updateUser');
  const userRef = getUserRef();
  if (!userRef) return;
  const res = await updateDoc(userRef, value);
  return res;
};

export const getNotificationsRef = (): CollectionReference<DocumentData> | false => {
  if (debug) console.log('getNotificationsRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const notifications = collection(db, 'homeCare', tenantId, 'notifications');
  return notifications;
};

export const getUserNotificationsRef = (): CollectionReference<DocumentData> | false => {
  if (debug) console.log('getUserNotificationsRef');
  const userRef = getUserRef();
  if (!userRef) return false;
  const notifications = collection(userRef, 'notifications');
  return notifications;
};

export const getOffersRef = (): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offer = collection(db, 'homeCare', tenantId, 'offers');
  return offer;
};

export const getRequestsFromCustomerRef = (): Query<DocumentData> | false => {
  if (debug) console.log('getRequestsFromCustomerRef');
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingOffers');
  const q = query(
    offers,
    where('houseId', '==', tenantId),
    where('customerDone', '==', false),
    orderBy('whenString', 'asc'),
  );
  return q;
};

export const getLeasingOffersToCustomer = (start: string, end: string): Query<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offers = collection(db, 'leasingEmployeeOffers');
  const q = query(
    offers,
    where('offerToIds', 'array-contains', tenantId),
    where('acceptedById', 'in', [tenantId, '']),
    where('whenString', '>=', start),
    where('whenString', '<=', end),
    orderBy('whenString', 'asc'),
  );
  return q;
};

export const getQualificationsRef = (): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const qualifications = collection(db, 'homeCare', tenantId, 'qualifications');
  return qualifications;
};

export const addQualification = async (value: Partial<iQualification>): Promise<string | false> => {
  const qualificationsRef = getQualificationsRef();
  if (!qualificationsRef) return false;
  const res = await addDoc(qualificationsRef, value);
  return res.id;
};

export const getRegionsRef = (): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const regions = collection(db, 'homeCare', tenantId, 'regions');
  return regions;
};

export const getRegionRef = (id: string): DocumentReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const region = doc(db, 'homeCare', tenantId, 'regions', id);
  return region;
};

export const updateRegionById = async (id: string, value: Partial<iRegion>): Promise<void> => {
  const docRef = getRegionRef(id);
  if (!docRef) return;
  const res = updateDoc(docRef, { ...value });
  return res;
};

export const addRegion = async (value: Partial<iRegion>): Promise<DocumentReference<DocumentData> | false> => {
  const regionsRef = getRegionsRef();
  if (!regionsRef) return false;
  const res = addDoc(regionsRef, value);
  return res;
};

export const getUsersRef = (): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const users = collection(db, 'homeCare', tenantId, 'users');
  return users;
};

const getTimeSlotRef = (id: string) => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const timeSlot = doc(db, 'homeCare', tenantId, 'timeSlots', id);
  return timeSlot;
};

export const getRegionsCollection = async (): Promise<QuerySnapshot<DocumentData> | false> => {
  const regionsRef = getRegionsRef();
  if (!regionsRef) return false;
  const docs = await getDocs(regionsRef);
  return docs;
};

export const updateRegion = async (id: string, value: Partial<iRegion>): Promise<void> => {
  const regionRef = getRegionRef(id);
  if (!regionRef) return;
  const res = await updateDoc(regionRef, value);
  return res;
};

export const updateTimeSlotById = async (id: string, value: Partial<iTimeSlot>): Promise<void> => {
  const docRef = getTimeSlotRef(id);
  if (!docRef) return;
  const res = updateDoc(docRef, { ...value });
  return res;
};

export const getTimeSlotsRef = (): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const timeSlots = collection(db, 'homeCare', tenantId, 'timeSlots');
  return timeSlots;
};

export const addTimeSlot = async (value: Partial<iTimeSlot>): Promise<DocumentReference<DocumentData> | false> => {
  const timeSlotsRef = getTimeSlotsRef();
  if (!timeSlotsRef) return false;
  const res = addDoc(timeSlotsRef, value);
  return res;
};

const getServiceRef = (id: string) => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const service = doc(db, 'homeCare', tenantId, 'services', id);
  return service;
};

export const updateServiceById = async (id: string, value: Partial<iService>): Promise<void> => {
  const docRef = getServiceRef(id);
  if (!docRef) return;
  const res = updateDoc(docRef, { ...value });
  return res;
};

export const getServicesRef = (): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const services = collection(db, 'homeCare', tenantId, 'services');
  return services;
};

export const addService = async (value: Partial<iService>): Promise<DocumentReference<DocumentData> | false> => {
  const servicesRef = getServicesRef();
  if (!servicesRef) return false;
  const res = addDoc(servicesRef, value);
  return res;
};

export const updateAUser = async (uid: string, value: Partial<iHomeCareUser>): Promise<void> => {
  const aUserRef = getAUserRef(uid);
  if (!aUserRef) return;
  const res = await updateDoc(aUserRef, value);
  return res;
};

export const addOffer = async (value: Partial<iHomeCareOffer>): Promise<string | false> => {
  const offersRef = getOffersRef();
  if (!offersRef) return false;
  const res = await addDoc(offersRef, value);
  return res.id;
};

export const getOfferRef = (offerId: string): DocumentReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const offer = doc(db, 'homeCare', tenantId, 'offers', offerId);
  return offer;
};

export const updateOfferById = async (offerId: string, value: Partial<iHomeCareOffer>): Promise<void> => {
  const offerRef = getOfferRef(offerId);
  if (!offerRef) return;
  const res = await updateDoc(offerRef, value);
  return res;
};

export const getSettingsRef = (): CollectionReference<DocumentData> | false => {
  const tenantId = checkTenantId();
  if (!tenantId) return false;
  const settings = collection(db, 'homeCare', tenantId, 'settings');
  return settings;
};

export const getSettingsLimitsRef = (): DocumentReference<DocumentData> | false => {
  const settingsRef = getSettingsRef();
  if (!settingsRef) return false;
  const limitsRef = doc(settingsRef, 'limits');
  return limitsRef;
};

export const getSettingsExportRef = (): DocumentReference<DocumentData> | false => {
  const settingsRef = getSettingsRef();
  if (!settingsRef) return false;
  const exportRef = doc(settingsRef, 'export');
  return exportRef;
};

export const updateSettingsExport = async (value: Partial<iHomeCareExportSettings>): Promise<void> => {
  const exportRef = getSettingsExportRef();
  if (!exportRef) return;
  await setDoc(exportRef, value, { merge: true });
};

export const getTimeSlotsCollection = async (): Promise<QuerySnapshot<DocumentData> | false> => {
  const timeSlotsRef = getTimeSlotsRef();
  if (!timeSlotsRef) return false;
  const docs = await getDocs(timeSlotsRef);
  return docs;
};
