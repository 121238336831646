import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { iService } from 'datamodel';

const initialState: iService[] = [];

export const homeCareServicesSlice = createSlice({
  name: 'homeCare',
  initialState,
  reducers: {
    setHomeCareServicesValue: (state, action: PayloadAction<iService[]>) => action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setHomeCareServicesValue } = homeCareServicesSlice.actions;

export default homeCareServicesSlice.reducer;
