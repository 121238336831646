import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { iCustomerNews } from 'datamodel';

const initialState: iCustomerNews[] = [];

export const customerNewsSlice = createSlice({
  name: 'customerNews',
  initialState,
  reducers: {
    setCustomerNewsValue: (state, action: PayloadAction<iCustomerNews[]>) => {
      return action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setCustomerNewsValue, reset } = customerNewsSlice.actions;

export default customerNewsSlice.reducer;
