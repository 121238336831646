import merge from 'lodash.merge';
import { iHouseSettings } from '../Interfaces/House';

export const mergeWithEmptyCustomerHouseSettings = (settings: iHouseSettings | undefined): iHouseSettings => {
  return merge({}, emptyCustomerHouseSettings, settings);
};

export const emptyCustomerHouseSettings: iHouseSettings = {
  compensation: {
    enabled: false,
    perOfferCompensationEnabled: false,
    types: [],
  },
  volunteerCompensation: {
    enabled: false,
    types: [],
  },
  poolManagement: {
    squashShifts: false,
    poolShiftStartsAtPoolShiftStart: false,
    poolAdminCanSetAvailability: true,
    poolAvailabilityNoChangeDays: 0,
  },
  managedInterDepartmentRequests: {
    enabled: false,
    onlySelf: false,
    onlyManager: false,
    onlyAllowed: false,
    globalAdminOverride: false,
  },
  amCancellations: {
    adminCanAssignAUserDirectly: true,
  },
  volunteerOffers: {
    enabled: false,
    userCanSelectDepartment: false,
    userCannotBookIfAnOfferIsAvailable: false,
    volunteerManagerRoleNeedsToApprove: {
      enabled: false,
      departmentRoleCanApprove: false,
      globalRoleCanApprove: false,
      roleCanApproveMultipleOffers: false,
    },
    onlyOwnDepartment: false,
    employeeSelectsTimeSpan: false,
  },
  targetEmployeeRequests: false,
  holidays: {
    enabled: false,
    states: {
      badenWuerttemberg: {
        enabled: false,
        name: 'Baden-Württemberg',
        short: 'BW',
      },
      bayern: {
        enabled: false,
        name: 'Bayern',
        short: 'BY',
      },
      berlin: {
        enabled: false,
        name: 'Berlin',
        short: 'BE',
      },
      brandenburg: {
        enabled: false,
        name: 'Brandenburg',
        short: 'BB',
      },
      bremen: {
        enabled: false,
        name: 'Bremen',
        short: 'HB',
      },
      hamburg: {
        enabled: false,
        name: 'Hamburg',
        short: 'HH',
      },
      hessen: {
        enabled: false,
        name: 'Hessen',
        short: 'HE',
      },
      mecklenburgVorpommern: {
        enabled: false,
        name: 'Mecklenburg-Vorpommern',
        short: 'MV',
      },
      niedersachsen: {
        enabled: false,
        name: 'Niedersachsen',
        short: 'NI',
      },
      nordrheinWestfalen: {
        enabled: false,
        name: 'Nordrhein-Westfalen',
        short: 'NW',
      },
      rheinlandPfalz: {
        enabled: false,
        name: 'Rheinland-Pfalz',
        short: 'RP',
      },
      saarland: {
        enabled: false,
        name: 'Saarland',
        short: 'SL',
      },
      sachsen: {
        enabled: false,
        name: 'Sachsen',
        short: 'SN',
      },
      sachsenAnhalt: {
        enabled: false,
        name: 'Sachsen-Anhalt',
        short: 'ST',
      },
      schleswigHolstein: {
        enabled: false,
        name: 'Schleswig-Holstein',
        short: 'SH',
      },
      thueringen: {
        enabled: false,
        name: 'Thüringen',
        short: 'TH',
      },
    },
  },
};
