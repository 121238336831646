import { Timestamp } from 'firebase/firestore';
import { eOfferTypes, ePoolItemRequestStatus, ePoolItemStatus, eShiftType } from '../../Enums';
import { iDepartmentRef } from '../Departments';
import { iQualification, iQualificationRef } from '../Qualifications';
import { iEmoji, iShift, iShiftRef } from '../Shifts';
import { iUserRef } from '../Users';

export type poolItemStatusTypes = 'option' | 'accepted' | 'offered' | 'rejected' | 'unavailable' | 'vacation';

export const mapPoolItemToStatus = (poolItem: iPoolItem | undefined): string => {
  if (!poolItem) return '';
  if (poolItem.vacation) return `poolStatus.${ePoolItemStatus.Vacation}`;
  if (poolItem.unavailable) return `poolStatus.${ePoolItemStatus.Unavailable}`;
  return `poolStatus.${poolItem.status}`;
};
export interface iPoolItem {
  id: string;
  userId: string;
  displayName: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  offeredAt: Timestamp;
  offeredBy: iUserRef;
  acceptedAt: Timestamp;
  rejectedAt: Timestamp;
  when: Timestamp;
  whenString: string;
  atDepartment: iDepartmentRef;
  shift: iShiftRef | null;
  poolShift: iPoolShiftRef | null;
  vacation: boolean | undefined;
  unavailable: boolean | undefined;
  createdBy: string;
  updatedBy: string;
  status: ePoolItemStatus;
  type: eOfferTypes.PoolItem;
  qualifications: iQualificationRef[];
  poolItemRequestId: string | undefined;
}

export interface iPoolItemRequest {
  id: string;
  whenString: string;
  requestedUserIds: string[];
  requestedUsers: iUserRef[];
  shiftId: string;
  departmentId: string;
  departmentName: string;
  shiftName: string;
  shiftFromTill: string;
  shiftIcon: string;
  shiftShort: string;
  qualifications: iQualification[];
  createdById: string;
  createdByDisplayName: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  status: ePoolItemRequestStatus;
  type: eOfferTypes.PoolItemRequest;
  note: string;
  assignedName: string;
  assignedAt: Timestamp;
  assignedBy: string;
  assignedPoolItemId: string;
  assignedToId: string;
  assignedToName: string;
  poolItems: iPoolItem[];
}

/**
 * pool user details
 */
export interface iPoolUserDetails {
  createdAt: Timestamp;
  updatedAt: Timestamp;
  vacationDaysTaken: number;
  vacationDays: number;
  /**
   * The number of hours the user has to schedule per week
   * @default 40
   */
  contractHours: number;
  /**
   * The length of a workday in hours
   * @default 8
   */
  workdayLength: number;
  /**
   * The break time in hours
   * @default 0.5
   */
  workdayBreakLength: number;
  /**
   * The number of days the user hast to have filled out in advance
   * @default 28
   */
  leadTime: number; //Days
}

export interface iPoolShift extends Omit<iShift, 'type'> {
  type: eShiftType.PoolShift;
}

export interface iPoolShiftRef {
  id: string;
  name: string;
  short?: string;
  icon?: iEmoji | null;
  fromTill?: string;
  type: eShiftType.PoolShift;
}
